exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-capgroup-funds-js": () => import("./../../../src/pages/capgroup/funds.js" /* webpackChunkName: "component---src-pages-capgroup-funds-js" */),
  "component---src-pages-capgroup-overview-js": () => import("./../../../src/pages/capgroup/overview.js" /* webpackChunkName: "component---src-pages-capgroup-overview-js" */),
  "component---src-pages-capgroup-resources-js": () => import("./../../../src/pages/capgroup/resources.js" /* webpackChunkName: "component---src-pages-capgroup-resources-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-fund-current-js": () => import("./../../../src/templates/fund-current.js" /* webpackChunkName: "component---src-templates-fund-current-js" */),
  "component---src-templates-fund-previous-js": () => import("./../../../src/templates/fund-previous.js" /* webpackChunkName: "component---src-templates-fund-previous-js" */),
  "component---src-templates-otherfund-js": () => import("./../../../src/templates/otherfund.js" /* webpackChunkName: "component---src-templates-otherfund-js" */)
}

